$(function () {
  function changeTab() {
    var tabName = $(this).data('tab');
    var target = $(this).attr('aria-controls');
    $('[data-tab="' + tabName + '"][aria-controls!="' + target + '"]').attr('aria-selected', 'false');
    $('[data-tab="' + tabName + '"][aria-controls="' + target + '"]').attr('aria-selected', 'true');

    $('[data-tab="' + tabName + '"][role="tabpanel"]').attr('aria-hidden', 'true');
    $('#' + target + '[data-tab="' + tabName + '"][role="tabpanel"]').attr('aria-hidden', 'false');
    return false;
  }

  $('[role="tab"]').on('click', changeTab);
});
